import React, { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-hot-toast';

import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  Tooltip,
  Typography,
} from '@mui/material';

import { useTheme } from 'hooks';

import { files, token, mask } from 'utils';

import { api } from 'services';

import { IBeneficiario } from 'context/interfaces';

const useStyle = (theme: Theme): any => ({
  bolder: { fontWeight: 600 },
  padding: { padding: 5 },
  paper: {
    width: '100%',
    padding: '1rem',
    marginTop: '5rem',
  },
  paperHeader: {
    textAlign: 'center',
    paddingTop: 30,
    marginBottom: 30,
    textTransform: 'uppercase',
  },
  paperBody: {
    padding: '1rem 0',
  },
  paperFooter: {
    padding: '1rem 0',
    '& fieldset': {
      paddingTop: '1rem',
    },
  },
  paperFooterAction: {
    marginTop: 30,
    textAlign: 'center',
  },
  table: {
    textAlign: 'center',
    marginTop: 30,
    [theme.breakpoints.down('md')]: {
      overflowX: 'scroll',
    },
    '& > p': {
      fontWeight: '600',
      textTransform: 'uppercase',
      marginBottom: '2rem',
    },
    '& > table': {
      border: '1px solid #e0e0e0',
    },
  },
});

export type DadosPreenchimento = {
  beneficiarios: string;
  dataPreenchimentoFormulario: string;
  nome: string;
  protocolo: string;
};

type Asset = {
  pdf: string;
  tipoDocumento: string;
};

const VisualizarDocumento: React.FC<DadosPreenchimento> = ({
  beneficiarios,
  dataPreenchimentoFormulario,
  nome,
  protocolo,
}) => {
  const { setShowLoading, systemTheme } = useTheme();
  const classes = useStyle(systemTheme);

  const [asset, setAsset] = useState<Asset | null>(null);

  const beneficiariosArray: IBeneficiario[] = useMemo(
    () => JSON.parse(beneficiarios),
    [beneficiarios],
  );

  const prettyDataPreenchimento = useMemo(
    () => new Date(dataPreenchimentoFormulario).toLocaleString(),
    [dataPreenchimentoFormulario],
  );

  const fetchAsset = (): void => {
    const url = `pdf-declaracao-saude/completo/${protocolo}`;
    const headers = { Authorization: `Bearer ${token.getAccessToken()}` };

    setShowLoading(true);

    api
      .get(url, { headers })
      .then(({ data }) => {
        setAsset(data);
      })
      .catch(() => {
        toast.error(
          'Houve um erro ao tentar baixar o documento! Tente novamente mais tarde.',
          { duration: 15000 },
        );
      })
      .finally(() => {
        setShowLoading(false);
      });
  };

  useEffect(() => {
    if (asset) {
      files.downloadPdfFromBase64(
        asset.pdf,
        `declaracao_de_saude_${protocolo}.pdf`,
      );
    }
  }, [asset]);

  return (
    <Paper elevation={5} sx={classes.paper}>
      <Box sx={classes.paperHeader}>
        <Typography sx={classes.bolder}>
          Visualização da Declaração de Saúde
        </Typography>
      </Box>
      <Box sx={classes.paperBody}>
        <Typography>
          <b>Nome do Proprietário:</b> {nome}
        </Typography>
        <Tooltip
          title="Data e hora em que o documento foi gerado pelo sistema"
          placement="bottom-start"
        >
          <Typography>
            <b>Data de Preenchimento:</b> {prettyDataPreenchimento}
          </Typography>
        </Tooltip>
        <Typography>
          <b>Número do Protocolo:</b> {protocolo}
        </Typography>

        <Box className={`${classes.table}`}>
          <Typography>Beneficiários</Typography>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <b>Nome</b>
                </TableCell>
                <TableCell align="center">
                  <b>Data de Nascimento</b>
                </TableCell>
                <TableCell align="center">
                  <b>Sexo</b>
                </TableCell>
                <TableCell align="center">
                  <b>CPF</b>
                </TableCell>
                <TableCell align="center">
                  <b>Telefone</b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {beneficiariosArray.map(b => (
                <TableRow key={b.idBeneficiario}>
                  <TableCell>{b.nome}</TableCell>
                  <TableCell align="center">{b.dataNasc}</TableCell>
                  <TableCell align="center">{b.sexo}</TableCell>
                  <TableCell align="center">
                    {b.cpf ? mask.formatCpfCnpj(b.cpf) : '-'}
                  </TableCell>
                  <TableCell align="center">
                    {b.telefone ? mask.formatTelefone(b.telefone) : '-'}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </Box>
      <Box sx={classes.paperFooter}>
        <Box sx={classes.paperFooterAction}>
          <Button
            type="button"
            variant="contained"
            color="primary"
            onClick={fetchAsset}
          >
            Visualizar Documento
          </Button>
        </Box>
      </Box>
    </Paper>
  );
};

export default VisualizarDocumento;

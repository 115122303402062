import React, { ReactNode } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Icon,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
} from '@mui/material';

import { useFormData, usePage, useTheme } from 'hooks';

const createRow = (id: string, item: string, cpt = '24 meses'): any => ({
  id,
  item,
  cpt,
});

const rows = [
  createRow(
    '01',
    'Doenças do aparelho cárdio-respiratório, conforme especificadas na Declaração de Saúde.',
  ),
  createRow(
    '02',
    'Doenças das veias, conforme especificadas na Declaração de Saúde.',
  ),
  createRow(
    '03',
    'Doenças endócrinas ou metabólicas, conforme especificadas na Declaração de Saúde.',
  ),
  createRow(
    '04',
    'Doenças do aparelho respiratório, conforme especificadas na Declaração de Saúde.',
  ),
  createRow(
    '05',
    'Doenças do aparelho digestivo, conforme especificadas na Declaração de Saúde.',
  ),
  createRow('06', 'Hérnias, conforme especificadas na Declaração de Saúde.'),
  createRow(
    '07',
    'Doenças renais ou da bexiga, conforme especificadas na Declaração de Saúde.',
  ),
  createRow(
    '08',
    'Doenças dos órgãos genitais masculinos, conforme especificadas na Declaração de Saúde.',
  ),
  createRow(
    '09',
    'Doenças ginecológicas e das mamas, conforme especificadas na Declaração de Saúde.',
  ),
  createRow(
    '10',
    'Doenças sexualmente transmissíveis e/ou relacionadas ao vírus HIV, conforme especificadas na Declaração de Saúde.',
  ),
  createRow(
    '11',
    'Doenças do sangue, conforme especificadas na Declaração de Saúde.',
  ),
  createRow(
    '12',
    'Tumores malignos (câncer), conforme especificadas na Declaração de Saúde.',
  ),
  createRow(
    '13',
    'Tumores benignos, conforme especificadas na Declaração de Saúde.',
  ),
  createRow(
    '14',
    'Doenças reumáticas ou colagenosas, conforme especificadas na Declaração de Saúde.',
  ),
  createRow(
    '15',
    'Doenças neurológicas, conforme especificadas na Declaração de Saúde.',
  ),
  createRow(
    '16',
    'Doenças da pele, conforme especificadas na Declaração de Saúde.',
  ),
  createRow(
    '17',
    'Doenças infectocontagiosas, conforme especificadas na Declaração de Saúde.',
  ),
  createRow(
    '18',
    'Problemas ortopédicos, traumatismo ou fraturas, conforme especificadas na Declaração de Saúde.',
  ),
  createRow(
    '19',
    'Transtornos clínicos, cirúrgicos ou transplantes programados, conforme especificadas na Declaração de Saúde.',
  ),
  createRow(
    '20',
    'Dependências químicas, conforme especificadas na Declaração de Saúde.',
  ),
  createRow(
    '21',
    'Transtornos psiquiátricos, conforme especificadas na Declaração de Saúde.',
  ),
  createRow(
    '22',
    'Doenças do ouvido, nariz ou garganta, conforme especificadas na Declaração de Saúde.',
  ),
  createRow(
    '23',
    'Doenças dos olhos e anexos, conforme especificadas na Declaração de Saúde.',
  ),
  createRow(
    '24',
    'Doenças ou má formação congênitas (de nascença) ou hereditárias, ou ainda sequelas de acidentes ou de moléstia adquirida, conforme especificadas na Declaração de Saúde.',
  ),
  createRow(
    '25',
    'Doenças não-relacionadas acima, conforme especificadas na Declaração de Saúde.',
  ),
  createRow(
    '26',
    'Marca-passo e/ou órteses ou próteses, conforme especificadas na Declaração de Saúde.',
  ),
];

const useStyle = (): any => ({
  bolder: { fontWeight: 600 },
  padding: { padding: 5 },
  text: { textAlign: 'justify', '& > p': { marginBottom: 10 } },
  paper: {
    display: 'grid',
    maxWidth: 850,
    width: '100%',
    padding: '1rem',
    marginTop: '2rem',
  },
  paperXs: {
    display: 'block',
    width: '100%',
    padding: '1rem',
    marginTop: '5rem',
  },
  paperHeader: {
    textAlign: 'center',
    paddingTop: 5,
    marginBottom: 3,
  },
  paperBody: {
    padding: '1rem 0',
  },
  paperFooter: {
    padding: '0rem 0',
    '& fieldset': {
      paddingTop: '1rem',
    },
  },
  paperFooterAction: {
    margin: '30px auto 0',
    width: '100%',
    maxWidth: 650,
    display: 'flex',
    justifyContent: 'space-between',
  },
  pageFooterActionSm: {
    margin: '30px auto 0',
    width: '100%',
    maxWidth: 650,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'inherit',
  },
  highlightedText: {
    border: '1px solid #000',
    margin: '1rem 0',
    '& > ul': {
      listStylePosition: 'inside',
      '& > li > p': {
        display: 'inline',
      },
    },
  },
  table: {
    marginTop: 3,
    border: '1px solid #e0e0e0',
    borderBottom: 'transparent',
    '& table > thead': {
      backgroundColor: '#e7e7e7',
    },
  },
  label: {
    textAlign: 'justify',
  },
  tableCell: {
    padding: '5px',
  },
  tableCellNumber: {
    padding: '8px',
    textAlign: 'center',
  },
  tableCellXs: {
    padding: '5px',
    textAlign: 'center',
  },
  button: {
    width: '110px',
  },
});

type FormValues = {
  aceiteCpt: boolean;
};

const schema = yup.object().shape({
  aceiteCpt: yup
    .bool()
    .oneOf([true], 'Este campo é obrigatório para prosseguir'),
});
interface AditivoCPTProps {
  operadora: ReactNode;
}

const AditivoCPT: React.FC<AditivoCPTProps> = ({ operadora }) => {
  const { nextPage, previousPage } = usePage();
  const classes = useStyle();
  const matchesXs = useMediaQuery('(min-width: 465px)');
  const matchesSm = useMediaQuery('(min-width: 600px)');
  const { formDataResponse, setFormDataResponse } = useFormData();
  const { control, handleSubmit, formState } = useForm<FormValues>({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      aceiteCpt: !!formDataResponse.aceiteCpt,
    },
  });

  const onSubmit: SubmitHandler<FormValues> = ({ aceiteCpt }) => {
    setFormDataResponse(prevState => {
      const state = { ...prevState };
      state.aceiteCpt = aceiteCpt;
      state.aceiteVeracidade = aceiteCpt;
      return state;
    });

    nextPage();
  };

  return (
    <Grid container justifyContent="center">
      <Paper elevation={5} sx={matchesXs ? classes.paper : classes.paperXs}>
        <Box sx={classes.paperHeader}>
          <Typography sx={classes.bolder}>
            ADITIVO DE COBERTURA PARCIAL TEMPORÁRIA
          </Typography>
        </Box>

        <Box sx={classes.paperBody}>
          <Box className={`${classes.padding} ${classes.text}`}>
            <Typography textAlign="justify" sx={{ textIndent: '5ch' }}>
              Este Aditivo é parte integrante do contrato que ora se assina,
              válido para todos os usuários indicados na proposta de adesão que
              optaram pela Cobertura Parcial Temporária.
            </Typography>
            {operadora === 1 && (
              <Typography textAlign="justify" sx={{ textIndent: '5ch' }}>
                De acordo com a Resolução Normativa nº 558, de 14/12/2022, as
                doenças e lesões preexistentes são passíveis de Cobertura
                Parcial Temporária, pelo prazo máximo de 24 (vinte e quatro)
                meses, sendo aplicável a eventos cirúrgicos, ao uso de leitos de
                alta tecnologia e a procedimentos de alta complexidade
                relacionados às doenças e lesões preexistentes.
              </Typography>
            )}
            {operadora === 2 && (
              <Typography textAlign="justify" sx={{ textIndent: '5ch' }}>
                De acordo com a Resolução CONSU nº 02, de 03/11/98, as doenças e
                lesões preexistentes são passíveis de Cobertura Parcial
                Temporária, pelo prazo máximo de 24 (vinte e quatro) meses,
                sendo aplicável a eventos cirúrgicos, ao uso de leitos de alta
                tecnologia e a procedimentos de alta complexidade relacionados
                às doenças e lesões preexistentes.
              </Typography>
            )}
            <Typography textAlign="justify" sx={{ textIndent: '5ch' }}>
              Com base nas respostas afirmativas da Declaração de Saúde,
              caracterizando assim, doença ou lesão preexistente, fica
              estabelecida a Cobertura Parcial Temporária conforme indicado
              abaixo:
            </Typography>
          </Box>

          <Box sx={classes.table}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell sx={classes.tableCellNumber}>#</TableCell>
                    <TableCell sx={classes.tableCell}>Item</TableCell>
                    <TableCell sx={classes.tableCellXs}>
                      Cobertura Parcial Temporária
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map(row => (
                    <TableRow key={row.id}>
                      <TableCell sx={classes.tableCellNumber}>
                        {row.id}
                      </TableCell>
                      <TableCell sx={classes.tableCell}>{row.item}</TableCell>
                      <TableCell sx={classes.tableCellXs}>{row.cpt}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>

        <Box sx={classes.paperFooter}>
          <Grid container component="form" direction="column">
            <FormControl
              component="fieldset"
              error={!!formState.errors.aceiteCpt}
            >
              <FormLabel component="legend">Aceite do proprietário</FormLabel>
              <FormControlLabel
                sx={classes.label}
                label="Declaro conhecer e aceitar os prazos de cobertura parcial temporária aplicados às doenças e lesões preexistentes por mim informadas na declaração de saúde, conforme descritos acima."
                control={
                  <Controller
                    name="aceiteCpt"
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        checked={field.value}
                        onChange={field.onChange}
                      />
                    )}
                  />
                }
              />
              <br />
            </FormControl>

            <Box
              sx={
                matchesSm
                  ? classes.paperFooterAction
                  : classes.pageFooterActionSm
              }
            >
              <Button
                type="button"
                color="secondary"
                sx={classes.button}
                variant="outlined"
                startIcon={<Icon>chevron_left</Icon>}
                onClick={previousPage}
              >
                Voltar
              </Button>
              <Button
                variant="contained"
                color="primary"
                sx={classes.button}
                disabled={!formState.isValid}
                type="button"
                onClick={handleSubmit(onSubmit)}
              >
                Enviar
              </Button>
            </Box>
          </Grid>
        </Box>
      </Paper>
    </Grid>
  );
};

export default AditivoCPT;
